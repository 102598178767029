import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import colors from '../atoms/colors';
import typography from '../atoms/Typography';
import { ArrowUpTray, XMark } from '../atoms/Icon/index';

interface Props {
    value?: File
    onChange?: (file: File | undefined) => void
}

const UploadButton = styled.button`
  border: 1px solid ${colors.text};
  background-color: transparent;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  ${typography.body};
  white-space: nowrap;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.1)
  }
`

const FileUpload: FC<Props> = ({ value, onChange }) => {
    const inputRef = useRef<HTMLInputElement>(null)

    return <div>
        { !value  && <>
            <UploadButton onClick={() => inputRef.current?.click()}>Upload <ArrowUpTray /></UploadButton>
            <input type="file"
                   style={{ display: 'none' }}
                   ref={inputRef}
                   accept="image/png"
                   onChange={e => onChange?.(e.target?.files?.[0])}/>
        </> }
        { value && <>
            <UploadButton onClick={() => onChange?.(undefined)}>{value.name} <XMark width="24" /></UploadButton>
        </> }

    </div>
}

export default FileUpload;
