import React, { FC } from 'react';
import styled from 'styled-components';
import colors from './colors';

interface Props {
    value: boolean
    onChange: (value: boolean) => void
}

const RadioDiv = styled.div`
    width: 24px;
    height: 24px;
    border: 1px solid ${colors.text};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const RadioSelected = styled.div`
    width: 16px;
    height: 16px;
    background: ${colors.brand};
    border-radius: 50%;
`;

const RadioButton: FC<Props> = ({ value, onChange }) => {
    return <RadioDiv onClick={() => onChange(!value)} role="radio" aria-checked={value}>
        { value && <RadioSelected /> }
    </RadioDiv>;
}

export default RadioButton;
