import React, { FC, useState } from 'react';
import { useAppContext } from '../context/AppContext';
import SentimentCard from '../atoms/SentimentCard';
import { VerticalSpaceFlex1 } from '../atoms/Space';
import { Label, Title } from '../atoms/Typography';
import { TextInput } from '../atoms/Input';
import { Row } from '../atoms/Layout';
import Button from '../atoms/Button';
import { ChannelCategory, createChannel } from '../api/channels';
import SelectInput, { SelectOption } from '../atoms/SelectInput';

const channelCategoryOptions: SelectOption[] = [
    { value: ChannelCategory.PHYSICAL_LOCATION, label: "Physical Location" },
    { value: ChannelCategory.EVENT, label: "Event" },
    { value: ChannelCategory.COMMUNITY_OUTREACH, label: "Community Outreach" },
    { value: ChannelCategory.DIGITAL, label: "Digital" },
]

const AddChannelCard: FC = () => {
    const channels = useAppContext().channels
    const [newChannelName, setNewChannelName] = useState<string>("")
    const [category, setCategory] = useState<ChannelCategory>(ChannelCategory.PHYSICAL_LOCATION);
    const [saving, setSaving] = useState<boolean>(false)

    const duplicateName = channels.value?.find(channel => channel.name === newChannelName)

    const onSubmit = async () => {
        if (!channels.value) return;

        setSaving(true)
        const channel = await createChannel(newChannelName, category);
        setSaving(false)
        setNewChannelName("")
        channels.setValue([...channels.value, channel])
    }

    return <SentimentCard>
        <VerticalSpaceFlex1 />
        <Row>
            <Title>Add Channel</Title>
        </Row>
        <Label>Channel Name</Label>
        <TextInput value={newChannelName} onChange={setNewChannelName} max={64} error={!!duplicateName}/>
        <Label>Channel Category</Label>
        <SelectInput options={channelCategoryOptions} value={category} onChange={value => setCategory(value as ChannelCategory)}/>
        <Button onClick={onSubmit}
                disabled={!channels.value || newChannelName.length === 0 || !!duplicateName}
                loading={saving}>Add</Button>
    </SentimentCard>
}

export default AddChannelCard;
