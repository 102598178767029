import {Topic} from "../api/scoring";
import React, {FC, useEffect, useMemo, useState} from "react";
import {v4} from "uuid";
import {Column, Row} from "../atoms/Layout";
import {Hint, Label, LabelBold} from "../atoms/Typography";
import {TextInput} from "../atoms/Input";
import Button from "../atoms/Button";
import {HorizontalSpaceFlex1} from "../atoms/Space";
import Panel from "../atoms/Panel";

interface Props {
    topic: Topic | undefined;
    onSave: (topic: Topic) => void;
    onDelete: (topicId: string) => void;
}

const EditScoringTopic: FC<Props> = ({topic, onSave, onDelete}) => {
    const [name, setName] = useState(topic?.name || '');
    const [description, setDescription] = useState(topic?.description || '');

    useEffect(() => {
        if (topic) {
            setName(topic.name);
            setDescription(topic.description || "");
        } else {
            setName('');
            setDescription('');
        }
    }, [topic]);

    const id = useMemo(() => topic?.id || v4(), [topic]);

    useEffect(() => {
        if (topic) {
            setName(topic.name);
            setDescription(topic.description || "");
        } else {
            setName('');
            setDescription('');
        }
    }, []);

    const hasUnsavedChanges = useMemo(() => {
        if (name !== topic?.name) {
            return true;
        }
        if (description !== topic?.description ?? "") {
            return true;
        }
        return false;
    }, [name, description, topic]);

    const save = () => {
        onSave({id, name, description: description === "" ? undefined : description});
    };

    return <Panel secondary>
        <Column gap="small">
            <LabelBold>{topic ? `Editing topic ${topic.name}` : "New Topic"}</LabelBold>
            <Label>Name:</Label>
            <Hint>This is the topic name as seen by the users</Hint>
            <TextInput value={name} onChange={setName} />
            <Label>Description:</Label>
            <Hint>This provides additional hints to the AI for what feedback to include in the topic</Hint>
            <TextInput value={description} onChange={setDescription} />
            <Row gap="small">
                <HorizontalSpaceFlex1 />
                {topic && <Button onClick={() => onDelete(topic.id)}>Delete Topic</Button>}
                <Button onClick={save} disabled={!hasUnsavedChanges}>Save Topic</Button>
            </Row>
        </Column>
    </Panel>
}

export default EditScoringTopic
