
import React, { FC } from 'react';
import { Column, Row } from '../../atoms/Layout';
import { Title } from '../../atoms/Typography';
import FeedbackViewer from '../../templates/FeedbackViewer';

const ViewFeedback: FC = () => {
    return <Column size="full" gap="large">
        <Row>
            <Column>
                <Title>Feedback</Title>
            </Column>
        </Row>
        <Row>
            <FeedbackViewer/>
        </Row>
    </Column>
}

export default ViewFeedback;
