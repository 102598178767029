import styled from 'styled-components';
import { ThemeProps } from '../theme';

const TextAreaInput = styled.textarea<ThemeProps>`
    width: 100%;
    border: 1px solid ${props => props.feedbackTheme.colors.inputOutline};
    background: ${props => props.feedbackTheme.colors.inputBackground};
    padding: 8px;
    color: ${props => props.feedbackTheme.colors.foreground};
    font-size: 16px;
    font-family: Tenor Sans;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
    resize: none;
    height: 182px;
    border-radius: 4px;

    &:focus {
      outline: 2px solid ${props => props.feedbackTheme.colors.primary};
    }
`;

export default TextAreaInput;
