import React, { FC, ReactNode, useEffect } from 'react';
import Panel from '../atoms/Panel';
import ChecklistItem from '../molecules/ChecklistItem';
import { Body, LinkButton, Subtitle } from '../atoms/Typography';
import Button from '../atoms/Button';
import { Column, Row } from '../atoms/Layout';
import { useAppContext } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { updateSpace } from '../api/spaces';
import styled from 'styled-components';
import Modal from '../molecules/Modal';
import { HorizontalSpaceFlex1 } from '../atoms/Space';
import ClipboardButton from 'atoms/ClipboardButton';

interface WelcomeChecklistItemProps {
    first: boolean
    last: boolean
    completed: boolean
    completeTitle: string
    incompleteTitle: string
    incompleteButtonText?: string
    onClick?: () => void
    children: ReactNode
}

const COPY_TEXT = `
Dear tenants,

We’re delighted to be working with Symantiq, a voice-first feedback tool to help us understand how you engage with the space and how best we can cater it to your requirements-usage.

All feedback is completely anonymous unless you tell us otherwise.

You will find tiles (QR-code cards) dotted around the building in the common area, café, gym, and lobby. You can leave feedback using your own mobile handset, by simply scanning a Symantiq tile and leaving a voice note. 
Feedback can be left in multiple languages from Arabic to Russian. 

All feedback received will be fed into bi-monthly action plans and be included in our annual report looking into how we can provide the best customer experience for you all.

Remember, the more feedback provided, the more opportunity we have to make your experience better. 

Many thanks, Property manager
`

const WelcomeChecklistItem: FC<WelcomeChecklistItemProps> = ({ first, last, completed, completeTitle, incompleteTitle, incompleteButtonText, onClick, children }) => {
    return <ChecklistItem checked={completed} first={first} last={last}>
        { completed && <Subtitle>{completeTitle}</Subtitle> }
        { !completed && <Row>
            <Column size="full" gap="small">
                <Subtitle>{incompleteTitle}</Subtitle>
                <Body>{children}</Body>
            </Column>
            { onClick && <Column>
                <Button onClick={onClick}>{incompleteButtonText}</Button>
            </Column> }
        </Row> }
    </ChecklistItem>
}

const Spacer = styled.div`
    width: 768px;
  
    @media (max-width: 768px) {
        width: calc(100vw - 100px);
    }
`

const WelcomeChecklist: FC = () => {
    const navigate = useNavigate()
    const [showEmailModal, setShowEmailModal] = React.useState(false)
    const { channels, feedbackPage, space } = useAppContext()

    useEffect(() => {
        void feedbackPage.load();
        void channels.load();
        void space.load();
    }, []);

    const feedbackPageSetUp = !feedbackPage.value?.isDefault
    const channelsSetUp = !!channels.value?.length
    const guestEmailSent = space.value?.welcomeEmailSent ?? true

    const allLoaded = feedbackPage.loaded && channels.loaded && space.loaded
    const show = allLoaded && (!feedbackPageSetUp || !channelsSetUp || !guestEmailSent)

    const markWelcomeEmailAsSent = () => {
        if (!space.value) {
            return
        }

        void updateSpace({
            ...space.value,
            welcomeEmailSent: true
        })

        space.setValue({
            ...space.value,
            welcomeEmailSent: true
        })
    }

    return <>
        <Panel hidden={!show}>
            {showEmailModal && <Modal title="Example email for you to use" onHide={() => setShowEmailModal(false)}>
                <Body>
                    Dear tenants,<br/><br/>

                    We’re delighted to be working with Symantiq, a voice-first feedback tool to help us understand how you engage with the space and how best we can cater it to your requirements-usage.<br/><br/>

                    All feedback is completely anonymous unless you tell us otherwise.<br/><br/>

                    You will find tiles (QR-code cards) dotted around the building in the common area, café, gym, and lobby. You can leave feedback using your own mobile handset, by simply scanning a Symantiq tile and leaving a voice note. <br/>
                    Feedback can be left in multiple languages from Arabic to Russian. <br/><br/>

                    All feedback received will be fed into bi-monthly action plans and be included in our annual report looking into how we can provide the best customer experience for you all.<br/><br/>

                    Remember, the more feedback provided, the more opportunity we have to make your experience better. <br/><br/>

                    Many thanks, Property manager
                </Body>
                <Row>
                    <HorizontalSpaceFlex1 />
                    <ClipboardButton content={COPY_TEXT}>Copy Text</ClipboardButton>
                </Row>
            </Modal>}
            <Column>
                <Spacer />
                <ChecklistItem checked={true} first={true} last={false}>
                    <Subtitle>You created your account</Subtitle>
                </ChecklistItem>
                <WelcomeChecklistItem completed={feedbackPageSetUp} first={false} last={false} completeTitle="You set up your feedback page" incompleteTitle="Set up your feedback page" incompleteButtonText="Set up" onClick={() => navigate('/dashboard/settings/feedback')}>
                    This is what guests will see when they scan the QR code or click on the link.
                </WelcomeChecklistItem>
                <WelcomeChecklistItem completed={channelsSetUp} first={false} last={false} completeTitle="You set up your channels" incompleteTitle="Set up your channels" >
                    Each channel represents an area of your space (i.e. 'Reception area' or 'Gym') or another feedback channel (i.e. 'Email Survey' or 'Link on Website'). Channels allow you to receive pertinent feedback and insights.  You can set up channels to the right.
                </WelcomeChecklistItem>
                <WelcomeChecklistItem completed={guestEmailSent} first={false} last={true} completeTitle="You've told your guests what to expect" incompleteTitle="Tell your guests what to expect" incompleteButtonText="Mark as done" onClick={markWelcomeEmailAsSent}>
                    Use our template <LinkButton onClick={() => setShowEmailModal(true)}>here</LinkButton> to email your guests to tell them about the new way to leave feedback.  This will encourage them to leave you more feedback.
                </WelcomeChecklistItem>
            </Column>
        </Panel>
    </>
}

export default WelcomeChecklist;
