
import React, { FC } from 'react';
import { FeedbackTheme } from '../theme';
import useRecorder from '../hooks/useRecorder';
import { Column } from '../atoms/Layout';
import { BodySmall, InlineLink, Subtitle } from '../atoms/typography';
import ButtonWithHalo from '../atoms/ButtonWithHalo';
import MicrophoneIcon from '../atoms/MicrophoneIcon';
import formatDuration from '../utils/formatDuration';
import Checkbox from '../atoms/Checkbox';
import { FeedbackPageAnonymizeSetting } from '../../api/feedbackPage';

interface Props {
    onRecordingComplete: (blob: Blob) => void
    feedbackTheme: FeedbackTheme
    anonymize: boolean
    onAnonymizeChange: (anonymize: boolean) => void
    onSwitchToText: () => void
}

const RecorderButton: FC<Props> = ({ feedbackTheme, onRecordingComplete, anonymize, onAnonymizeChange, onSwitchToText }) => {
    const [haloSize, setHaloSize] = React.useState(0);
    const recorder = useRecorder(setHaloSize);

    const toggleRecording = () => {
        if (recorder.isRecording) {
            const blob = recorder.stopRecording();
            if (blob) {
                onRecordingComplete(blob);
            }
        } else {
            recorder.startRecording();
        }
    }

    let subtitle = recorder.isRecording ? 'Tap again to stop recording' : 'Tap to start recording';
    if (recorder.microphoneDenied) {
        subtitle = 'We were unable to access your microphone. Please check your browser settings or try refreshing the page.'
    }

    return <Column>
        <Subtitle center feedbackTheme={feedbackTheme}>
            {subtitle}
        </Subtitle>
        <ButtonWithHalo haloSize={haloSize} feedbackTheme={feedbackTheme} active={recorder.isRecording} onClick={toggleRecording}>
            {recorder.isRecording ? formatDuration(recorder.duration) : <MicrophoneIcon color="white" />}
        </ButtonWithHalo>
        <Subtitle feedbackTheme={feedbackTheme} center><InlineLink feedbackTheme={feedbackTheme} onClick={onSwitchToText}>Type a note instead</InlineLink></Subtitle>
        { feedbackTheme.anonymize === FeedbackPageAnonymizeSetting.OPTIONAL && <Checkbox value={anonymize} label="Anonymize my Voice" onChange={onAnonymizeChange} feedbackTheme={feedbackTheme} /> }
        { anonymize && <BodySmall center feedbackTheme={feedbackTheme}>Your voice will be modified to keep your feedback anonymous</BodySmall> }
    </Column>

}

export default RecorderButton;
