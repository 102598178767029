import React, {FC, useEffect, useMemo} from 'react';
import { useAppContext } from '../context/AppContext';
import { Column, Row } from '../atoms/Layout';
import Loading from '../atoms/Loading';
import DateRangeInput from '../atoms/DateRangeInput';
import moment from 'moment';
import FeedbackFilters from '../organisms/FeedbackFilters';
import { FeedbackFilter, filterFeedback } from '../model/feedback';
import FeedbackItem from '../organisms/FeedbackItem';
import {FeedbackResponse} from "../api/feedback";

interface ScrollableData<T> {
    data: T[];
    nextSlice: () => void;
}

const useScrolling = (data: FeedbackResponse[]): ScrollableData<FeedbackResponse> => {
    const [value, setValue] = React.useState<any[]>([]);

    const nextSlice = () => {
        setValue(prevValue => {
            const sliceStart = prevValue.length;
            const sliceEnd = sliceStart + 50;
            const nextSlice = data.slice(sliceStart, sliceEnd);
            return [...prevValue, ...nextSlice];
        });
    };

    useEffect(() => {
        setValue(data.slice(0, 50));
    }, [data]);

    return {
        data: value,
        nextSlice,
    }
};

const FeedbackViewer: FC = () => {
    const { feedback, channels } = useAppContext()
    const [filter, setFilter] = React.useState<FeedbackFilter>({
        startDate: moment().subtract(30, 'days').startOf('day'),
        endDate: moment().endOf('day'),
    })
    const filteredFeedback = useMemo(() => filterFeedback(feedback.value ?? [], filter).sort((a, b) => {
        return -moment(a.createdAt).diff(moment(b.createdAt));
    }), [feedback.value, filter])
    const { data: streamingFeedback, nextSlice } = useScrolling(filteredFeedback)
    const scrollableRef = React.useRef<HTMLDivElement>(null)

    useEffect(() => {
        void feedback.load()
        void channels.load()
    }, [])

    const handleScroll = () => {
        if (!scrollableRef.current) {
            return;
        }

        const scrollable = scrollableRef.current;
        const nearBottom = scrollable.scrollTop + scrollable.clientHeight >= scrollable.scrollHeight - 100;
        if (nearBottom) {
            nextSlice();
        }
    };

    useEffect(() => {
        console.log("Adding scroll listener")
        if (!scrollableRef.current) {
            return;
        }
        scrollableRef.current.addEventListener('scroll', handleScroll);
    }, [scrollableRef.current]);

    if (feedback.loading || channels.loading) {
        return <Row center><Loading size="large" /> </Row>
    }

    return <Column size="full" gap="large">
        <Row>
            <DateRangeInput startDate={filter.startDate} endDate={filter.endDate} onChange={(startDate, endDate) => {
                setFilter({
                    ...filter,
                    startDate: startDate,
                    endDate: endDate,
                })
            }} />
        </Row>
        <Row gap="large">
            <FeedbackFilters filter={filter} setFilter={setFilter} feedback={feedback.value ?? []} channels={channels.value ?? []} />
            <Column size="full" gap="large" style={{ overflow: 'auto', maxHeight: 'calc(100vh - 240px)' }} ref={scrollableRef}>
                { streamingFeedback.map((feedback) => {
                    return <FeedbackItem key={feedback.id} feedback={feedback} channels={channels.value ?? []} />
                })}
            </Column>
        </Row>
    </Column>
}

export default FeedbackViewer;
