import axios from "axios";

type ContextFieldType = 'longText' | 'shortText' | 'radioGroup' | 'tagGroup' //| 'sliders'

export interface BaseContextField {
    key: string;
    type: ContextFieldType;
    question: string;
    infoTooltip?: string;
    hint?: string;
}

export interface LongTextContextField extends BaseContextField {
    type: 'longText';
    placeholder?: string;
}

export interface ShortTextContextField extends BaseContextField {
    type: 'shortText';
    placeholder?: string;
}

export interface TagGroupContextField extends BaseContextField {
    type: 'tagGroup';
    options: string[];
    maxSelections?: number;
}

export interface RadioGroupContextField extends BaseContextField {
    type: 'radioGroup';
    options: string[];
}

export type ContextField = LongTextContextField | ShortTextContextField | TagGroupContextField | RadioGroupContextField;

export const getContextFields = async (): Promise<ContextField[]> => {
    return (await axios.get(`/api/space/context/fields`)).data
}