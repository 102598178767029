import axios from "axios";

export interface MailSubscription {
    id: string;
    topicId: string;
    email: string;
}


export const unsubscribe = async (subscriptionId: string): Promise<void> => {
    await axios.post(`/api/mail/unsubscribe/${subscriptionId}`)
}

export const unsubscribeAll = async (subscriptionId: string): Promise<void> => {
    await axios.post(`/api/mail/unsubscribe/${subscriptionId}/all`)
}
