import axios from 'axios';

export interface Space {
    id: string
    name: string
    welcomeEmailSent: boolean,
    contextValues: Record<string, string>
}

interface UpdateSpaceCommand {
    name: string
    welcomeEmailSent: boolean,
    contextValues: Record<string, string>
}

export const getSpace = async (): Promise<Space> => {
    return (await axios.get(`/api/space`)).data
}

export const updateSpace = async (space: UpdateSpaceCommand): Promise<Space> => {
    return (await axios.put(`/api/space`, space)).data
}
