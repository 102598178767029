
import React, { FC, useRef } from 'react';
import { FeedbackTheme } from '../theme';
import Background from '../atoms/Background';
import Layout, { Column } from '../atoms/Layout';
import ProgressBar from '../atoms/ProgressBar';
import Logo from '../atoms/Logo';
import Transition from '../atoms/Transition';
import PromoterScoreStep from '../organisms/PromoterScoreStep';
import { FeedbackSubmission } from '../../api/feedback';
import FeedbackStep from '../organisms/FeedbackStep';
import { Hint, Subtitle, Title } from '../atoms/typography';
import { VerticalSpaceFlex1 } from '../../atoms/Space';
import Footer from '../molecules/Footer';

interface Props {
    theme: FeedbackTheme
    onSubmit: (submission: FeedbackSubmission) => Promise<void>
}

enum Step {
    FEEDBACK,
    PROMOTER_SCORE
}

const calculateSteps = (theme: FeedbackTheme) => {
    const steps = [];

    if (theme.askPromoterScore) {
        steps.push(Step.PROMOTER_SCORE);
    }

    steps.push(Step.FEEDBACK);

    return steps;
}

const FeedbackWizard: FC<Props> = ({ theme, onSubmit }) => {
    const steps = calculateSteps(theme);
    const [stepIndex, setStepIndex] = React.useState(0);
    const submission = useRef<Partial<FeedbackSubmission>>({});
    const [saving, setSaving] = React.useState(false);
    const [saved, setSaved] = React.useState(false);
    const currentStep = (!saved && !saving) ? steps[stepIndex] : null;

    const submit = async () => {
        setSaving(true);
        await onSubmit(submission.current as FeedbackSubmission);
        setSaved(true);
        setSaving(false);
    }

    const onStepSubmit = (stepSubmission: Partial<FeedbackSubmission>) => {
        submission.current = {
            ...submission.current,
            ...stepSubmission
        };

        if (stepIndex < steps.length - 1) {
            setStepIndex(stepIndex + 1);
        } else {
            void submit();
        }
    }

    return <Background feedbackTheme={theme}>
        {!saving && !saved && <ProgressBar feedbackTheme={theme} stepCount={steps.length} currentStep={stepIndex} />}
        <Layout>
            <Logo feedbackTheme={theme} />
            <Transition>
                <div key={stepIndex}>
                    {currentStep == Step.FEEDBACK && <FeedbackStep feedbackTheme={theme} onSubmit={onStepSubmit} />}
                    {currentStep == Step.PROMOTER_SCORE && <PromoterScoreStep feedbackTheme={theme} onSubmit={onStepSubmit} /> }
                    {saving && <Title feedbackTheme={theme}>Saving your feedback</Title>}
                    {saved && <Column>
                        <Title center feedbackTheme={theme}>Thank you for your feedback!</Title>
                        <Subtitle center feedbackTheme={theme}>Your feedback has been saved</Subtitle>
                        <Hint feedbackTheme={theme}>You can close this window now</Hint>
                    </Column>}`
                </div>
            </Transition>
            <VerticalSpaceFlex1 />
            <Footer feedbackTheme={theme} />
        </Layout>
    </Background>
}

export default FeedbackWizard;
