import React, { FC } from 'react';
import styled from 'styled-components';
import colors from '../atoms/colors';
import Panel from '../atoms/Panel';
import { Column, Row } from '../atoms/Layout';
import { HorizontalSpaceFlex1 } from '../atoms/Space';
import { XMark } from '../atoms/Icon/index';
import { Title } from '../atoms/Typography';

const ModalBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: ${colors.modalBackground};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const ModalPanel = styled(Panel)`
    max-width: 800px;
`;

interface Props {
    onHide?: () => void;
    title: string;
    children: React.ReactNode;
}

const Modal: FC<Props> = ({ onHide, title, children }) => {
    return <ModalBackground>
        <ModalPanel>
            <Column gap="medium">
                <Row vcenter gap="large">
                    <Title>{title}</Title>
                    <HorizontalSpaceFlex1 />
                    <XMark onClick={onHide} width="24" height="24" style={{ cursor: 'pointer' }}/>
                </Row>
                <Row>
                    <Column size="full">
                        { children }
                    </Column>
                </Row>
            </Column>
        </ModalPanel>
    </ModalBackground>
}

export default Modal;
