
import React, { FC, useEffect } from 'react';
import { Label, LabelBold, Title } from 'atoms/Typography';
import { useAppContext } from 'context/AppContext';
import Loading from 'atoms/Loading';
import { Check, ChevronDown } from 'atoms/Icon';
import { Column, Row } from 'atoms/Layout';
import styled from 'styled-components';
import colors from 'atoms/colors';
import { HorizontalSpace2 } from 'atoms/Space';

interface Props {
    channelId?: string;
    onChange?: (channelId?: string) => void;
    showAllChannelsOption?: boolean;
}

const ChannelSelectOverlay = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: ${colors.panel};
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  z-index: 1;
`;

const SelectRow: FC<{ selected?: boolean, onClick?: () => void, children: React.ReactNode }> = ({ selected, onClick, children }) => {
    if (selected) {
        return <Row nowrap gap="small" vcenter>
            <Check height={16} width={16}/>
            <LabelBold onClick={onClick}>{children}</LabelBold>
        </Row>;
    }

    return <Row nowrap gap="small" vcenter>
        <HorizontalSpace2 />
        <Label onClick={onClick}>{children}</Label>
    </Row>;
}

const ChannelSelect: FC<Props> = ({ channelId, onChange, showAllChannelsOption}) => {
    const { channels } = useAppContext();
    const [showMenu, setShowMenu] = React.useState(false);
    const rowRef = React.useRef<HTMLDivElement>(null);
    const currentChannel = channels.value?.find(channel => channel.id === channelId);

    useEffect(() => {
        void channels.load();
    }, [])

    useEffect(() => {
        if (!showMenu) {
            return;
        }
        // When the user clicks outside the channel select, close the menu
        const listener = (event: MouseEvent) => {
            if (showMenu && rowRef.current && !event.composedPath().includes(rowRef.current)) {
                setShowMenu(false);
            }
        }
        document.addEventListener('click', listener);
        return () => document.removeEventListener('click', listener);
    }, [showMenu])

    if (channels.loading) {
        return <Loading size="medium" />
    }

    return <Row vcenter nowrap gap="small" style={{ position: 'relative', cursor: 'pointer' }} onClick={() => setShowMenu(!showMenu)} ref={rowRef}>
        <Title nowrap style={{ maxWidth: '100%'}}>
            {currentChannel?.name || 'All Channels'}
        </Title>
        <ChevronDown height={40} width={40} />
        { showMenu && <ChannelSelectOverlay>
            <Column padding="small" gap="small">
                { showAllChannelsOption && <SelectRow selected={!channelId} onClick={() => onChange?.(undefined)}>All Channels</SelectRow> }
                {channels.value?.map(channel => <SelectRow key={channel.id} selected={channel.id === channelId} onClick={() => onChange?.(channel.id)}>{channel.name}</SelectRow>)}
            </Column>
        </ChannelSelectOverlay> }
    </Row>
}

export default ChannelSelect;
