
import React, {FC, useEffect} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Column, Row } from 'atoms/Layout';
import ChannelSelect from 'organisms/ChannelSelect';
import InsightThemes from 'templates/InsightThemes';
import InsightScores from 'templates/InsightScores';
import { HorizontalSpaceFlex1 } from 'atoms/Space';
import ClipboardButton from 'atoms/ClipboardButton';
import {useAppContext} from "../../context/AppContext";

const Insights: FC = () => {
    const { channelId } = useParams();
    const { channels } = useAppContext();
    const navigate = useNavigate();

    const changeChannel = (channelId?: string) => {
        if (!channelId) {
            navigate('/dashboard/insights')
            return
        }

        navigate(`/dashboard/insights/${channelId}`)
    }

    useEffect(() => {
        if (!channels.value) {
            return;
        }

        if (!channelId) {
            changeChannel(channels.value[0].id);
            return;
        }
    }, [channels.value, channelId])

    return <Column size="full" gap="large">
        <Row>
            <Column>
                <ChannelSelect channelId={channelId} onChange={changeChannel}/>
            </Column>
            <HorizontalSpaceFlex1 />
        </Row>
        <Row gap="large">
            <InsightThemes channelId={channelId} />
            <InsightScores channelId={channelId} />
        </Row>
    </Column>
}

export default Insights;
