import React, {FC} from 'react';
import {ThemeProps} from '../theme';
import {FeedbackPageStyle} from "../../api/feedbackPage";


const SymantiqLogo: FC<ThemeProps> = ({ feedbackTheme }) => {
    return <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="107" height="28"
    viewBox="0 0 320.63 88.37" stroke={feedbackTheme.style === FeedbackPageStyle.LIGHT ? 'black' : 'white'}>
        <path d="M42.35,22.53l0.21-0.07l-27.11,7.45h-0.01c-0.41,0.09-0.71,0.41-0.74,0.8c-0.01,0.02-0.01,0.04-0.01,0.06v6.31h0.01
    c-0.01,0.02-0.01,0.05-0.01,0.07c0,0.47,0.4,0.85,0.91,0.88c0.02,0.01,0.05,0.01,0.07,0.01l0.48-0.12l26.38-6.64L42.5,40.5
    l-32.35,6.35V24.38L42.6,13.45l-0.03,9.02C42.5,22.49,42.43,22.51,42.35,22.53z"/>
    <path d="M14.7,61.52c0.03-0.46,0.46-0.82,0.97-0.82l26.92-2.43l0.01-8.93l-32.45,4.68v22.47l32.35-0.1l0.03-8.55v-0.13l-26.86,1.31
    c-0.54,0-0.98-0.4-0.98-0.89c0-0.02,0-0.04,0.01-0.06h-0.01v-6.48C14.69,61.57,14.69,61.54,14.7,61.52z"/>
    <g>
    <path d="M14.7,61.52c-0.01,0.02-0.01,0.05-0.01,0.07L14.7,61.52L14.7,61.52z"/>
        </g>
    <polygon points="44.36,13.45 76.73,24.37 76.73,31.56 44.37,22.52 "/>
    <polygon points="44.36,67.72 76.73,69.04 76.73,76.49 44.37,76.39 "/>
    <polygon points="44.51,49.41 44.35,49.4 44.35,49.39 "/>
    <path d="M76.73,39.38v22.47l-32.37-3.68l-0.01-8.77l26.56,4.09c0.03,0.01,0.07,0.01,0.11,0.01c0.02,0.01,0.05,0.01,0.08,0.01
	c0.51,0,0.94-0.37,0.97-0.84c0.01-0.02,0.01-0.05,0.01-0.07v-6.23h-0.01c0.01-0.02,0.01-0.04,0.01-0.06c0-0.05,0-0.09-0.02-0.13
	c-0.05-0.43-0.43-0.73-0.96-0.81L50.75,41.7l-1.48-0.27l-0.62-0.11c-0.24-0.04-0.47-0.08-0.7-0.12c-1.12-0.19-2.74-0.48-3.59-0.63
	l0.01-9.33L76.73,39.38z"/>
    <g>
        <g>
            <path d="M16.15,37.92c-0.14,0.08-0.31,0.12-0.48,0.12L16.15,37.92z"/>
        </g>
        <path d="M14.7,30.71c-0.01,0.02-0.01,0.04-0.01,0.06L14.7,30.71L14.7,30.71z"/>
    </g>
    <g>
        <path d="M101.96,64.05c-4.92,0-9.37-1.97-9.37-6.76h5.84c0,1.64,1.68,2.56,3.53,2.56c1.72,0,3.23-0.71,3.23-2.35
		c0-1.72-1.97-2.06-3.4-2.44l-3.11-0.8c-3.15-0.8-5.54-2.35-5.54-6.22c0-4.41,4.91-6.34,9.03-6.34c4.45,0,8.49,1.97,8.49,6.26h-5.67
		c0-1.43-1.39-2.06-2.81-2.06c-1.72,0-2.77,0.5-2.77,1.89c0,1.22,1.39,1.72,3.11,2.18l2.94,0.8c3.11,0.84,5.92,2.44,5.92,6.34
		C111.37,61.74,106.88,64.05,101.96,64.05z"/>
        <path d="M129.05,71.49h-6.26l2.86-8.44l-8.28-20.71h6.72L128.76,57l4.75-14.66h6.18L129.05,71.49z"/>
        <path d="M174.54,63.42v-12.9c0-2.27-0.67-4.62-3.78-4.62c-1.97,0-3.78,1.76-3.78,4.83v12.69h-6.3v-12.9c0-2.27-0.67-4.62-3.78-4.62
		c-1.97,0-3.78,1.76-3.78,4.83v12.69h-6.3V42.33h6.26v2.56c1.3-1.85,3.36-3.19,6.55-3.19c3.44,0,5.75,1.85,6.64,4.16
		c1.93-2.9,4.03-4.16,7.23-4.16c5.29,0,7.35,3.99,7.35,7.69v14.03H174.54z"/>
        <path d="M203.81,63.42l-0.21-2.56c-1.39,2.14-3.7,3.19-6.55,3.19c-4.03,0-7.52-2.02-7.52-6.38c0-5.71,5.88-7.39,10.75-7.39h2.6
		v-2.39c0-1.13-0.67-2.6-3.19-2.6c-2.48,0-3.15,1.89-3.15,3.02h-6.05c0-5.29,4.96-6.59,9.41-6.59c4.66,0,9.28,1.55,9.28,7.9v11.09
		c0,1.05,0.21,2.73,0.21,2.73H203.81z M202.89,53.68H201c-3.11,0-5.5,0.63-5.5,3.15c0,1.89,1.01,3.02,3.57,3.02
		c3.02,0,3.82-2.52,3.82-4.83C202.89,55.02,202.89,54.14,202.89,53.68z"/>
        <path d="M232.79,63.42v-12.9c0-2.27-0.67-4.62-3.78-4.62c-1.97,0-3.78,1.76-3.78,4.83v12.69h-6.3V42.33h6.26v2.65
		c1.3-1.93,3.36-3.28,6.55-3.28c4.92,0,7.35,3.36,7.35,7.94v13.78H232.79z"/>
        <path d="M256.86,46.54v9.79c0,2.77,0.42,3.36,2.6,3.36c0.67,0,1.34-0.08,2.02-0.17v3.91c-0.8,0.21-2.27,0.63-4.54,0.63
		c-4.92,0-6.38-2.1-6.38-7.14V46.54h-4.12v-4.2h4.12v-4.16l6.3-2.27v6.43h4.75v4.2H256.86z"/>
        <path d="M270.29,38.34v-5.88h6.3v5.88H270.29z M270.29,63.42V42.33h6.3v21.09H270.29z"/>
        <path d="M300.87,71.49V60.44c-1.34,2.52-3.78,3.61-6.51,3.61c-6.17,0-9.07-5.42-9.07-11.17c0-6.13,3.32-11.17,9.87-11.17
		c2.18,0,4.62,1.3,5.71,3.11v-2.48h6.3v29.15H300.87z M296.34,45.9c-4.03,0-4.54,3.57-4.54,6.97c0,3.44,0.5,6.97,4.54,6.97
		c3.65,0,4.5-3.82,4.5-6.97C300.83,49.69,299.99,45.9,296.34,45.9z"/>
    </g>
</svg>


}

export default SymantiqLogo;
