
import React, { FC } from 'react';
import styled from 'styled-components';
import colors from './colors';

interface CircleProps {
    checked: boolean;
}

const ChecklistCheckmarkCircle: FC<CircleProps> = ({ checked }) => {
    if (checked) {
        return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="11" fill="#61CDA8"/>
            <path d="M6 11.5L10 15.5L16 6.5" stroke="#F8F8FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    } else {
        return <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11" cy="11" r="10.5" fill="white" stroke={colors.borderSecondary}/>
        </svg>
    }
}

interface Props {
    checked: boolean;
    first: boolean;
    last: boolean;
}

const Container = styled.div`
    position: relative;
    width: 22px;
    padding: 18px 0;
`

const TopLine = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 16px;
    border-left: 1px dashed ${colors.borderSecondary};
`

const BottomLine = styled.div`
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: calc(100% - 40px);
    border-left: 1px dashed ${colors.borderSecondary};
`;


const ChecklistCheckmark: FC<Props> = ({ checked, first, last }) => {
    return <Container>
        { !first && <TopLine /> }
        <ChecklistCheckmarkCircle checked={checked} />
        { !last && <BottomLine /> }
    </Container>
}

export default ChecklistCheckmark;
