import styled from 'styled-components';
import { FeedbackTheme } from '../theme';

interface TypographyProps {
    feedbackTheme: FeedbackTheme;
    center?: boolean;
}

export const Hint = styled.div<TypographyProps>`
    color: ${props => props.feedbackTheme.colors.hint};
    font-size: 15px;
    font-family: Tenor Sans;
    font-weight: 400;
    word-wrap: break-word;
    text-align: ${props => (props.center ? 'center' : 'left')};
`;

export const BodySmall = styled.div<TypographyProps>`
    color: ${props => props.feedbackTheme.colors.foreground};
    font-size: 14px;
    font-family: Tenor Sans;
    font-weight: 500;
    letter-spacing: 0.14px;
    word-wrap: break-word;
    text-align: ${props => (props.center ? 'center' : 'left')};
`;

export const Title = styled.div<TypographyProps>`
    color: ${props => props.feedbackTheme.colors.foreground};
  font-size: 32px;
  font-family: Tenor Sans;
  font-weight: 500;
  letter-spacing: 0.40px;
  word-wrap: break-word;
  text-align: ${props => (props.center ? 'center' : 'left')};
`;

export const Subtitle = styled.div<TypographyProps>`
    color: ${props => props.feedbackTheme.colors.foreground};
    font-size: 20px;
    font-family: Tenor Sans;
    font-weight: 400;
    line-height: 30px;
    word-wrap: break-word;
    text-align: ${props => (props.center ? 'center' : 'left')};
`;

export const Label = styled.div<TypographyProps>`
    color: ${props => props.feedbackTheme.colors.foreground};
    font-size: 18px;
    font-family: Tenor Sans;
    font-weight: 500;
    letter-spacing: 0.18px;
    word-wrap: break-word;
    text-align: ${props => (props.center ? 'center' : 'left')};
`;

export const InlineLink = styled.a<TypographyProps>`
    color: ${props => props.feedbackTheme.colors.link};
    cursor: pointer;
    white-space: nowrap;
  
    &:hover {
        color: ${props => props.feedbackTheme.colors.primaryHover};
    }
`;
