import styled from 'styled-components';
import { Sentiment, SENTIMENT_COLORS } from 'model/feedback';

const SentimentCard = styled.div<{ sentiment?: Sentiment }>`
    min-width: 400px;
    height: 400px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    
    border-radius: 20px;
    background: ${props => props.sentiment ? SENTIMENT_COLORS[props.sentiment] : '#E0E0ED'};
    padding: 35px;
    box-shadow: 0 4px 35px 0 #E0E0ED;
    cursor: pointer;
  
    transition: transform 0.2s ease-in-out;
  
    &:hover {
      transform: translate(0px, -5px);
    }
  
    @media (max-width: 768px) {
        min-width: 300px;
        height: 300px;
        flex-grow: 1;
    }
`;

export default SentimentCard;
