import {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {unsubscribe, unsubscribeAll} from "../api/mail";
import {Column, Row} from "../atoms/Layout";
import {Title} from "../atoms/Typography";
import Loading from "../atoms/Loading";
import Button from "../atoms/Button";
import Logo from "../atoms/Logo";


type State = 'saving' | 'saved' | 'savingAll' | 'savedAll'

const MailUnsubscribe: FC = () => {
    const params = useParams();
    const subscriptionId = params.subscriptionId!;
    const [state, setState] = useState<State>('saving');

    useEffect(() => {
        setState('saving');
        unsubscribe(subscriptionId).then(() => setState('saved'));
    }, [subscriptionId]);

    const handleUnsubscribeAll = () => {
        setState('savingAll');
        unsubscribeAll(subscriptionId).then(() => setState('savedAll'));
    }

    return (
        <Column center size="full" padding="large">
            <Row center>
                <Column gap="medium" size="full" center>
                    <Logo />
                    {state === 'saving' && <>
                        <Title style={{ textAlign: "center"}}>You are being unsubscribed from Symantiq notifications...</Title>
                        <Loading size="large"/>
                    </>}
                    {state === 'saved' && <>
                        <Title style={{ textAlign: "center"}}>You have been successfully unsubscribed from this type of notification.</Title>
                        <Button onClick={handleUnsubscribeAll}>Unsubscribe from all Symantiq emails</Button>
                    </>}
                    {state === 'savingAll' && <>
                        <Title style={{ textAlign: "center"}}>Unsubscribing from all notifications...</Title>
                        <Loading size="large"/>
                    </>}
                    {state === 'savedAll' && <>
                        <Title style={{ textAlign: "center"}}>You have been successfully unsubscribed from all Symantiq notifications.</Title>
                    </>}
                </Column>
            </Row>
        </Column>
    );
}

export default MailUnsubscribe;
