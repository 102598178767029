import axios from "axios";

export interface Scoring {
    id: string;
    name: string;
    topics: Topic[];
    scoringCategories: ScoringCategory[];
}

export interface ScoringInfo {
    id: string;
    name: string;
}

export interface Topic {
    id: string;
    name: string;
    description?: string;
}

export interface ScoringCategory {
    id: string;
    name: string;
    description: string;
    topics: ScoringCategoryTopic[];
}

export interface ScoringCategoryTopic {
    topicId: string;
    weight: number;
}

export const getScorings = async (): Promise<ScoringInfo[]> => {
    return (await axios.get('/api/admin/scorings')).data;
}

export const getScoring = async (id: string): Promise<Scoring> => {
    return (await axios.get(`/api/scoring/${id}`)).data;
}

export const saveScoring = async (scoring: Scoring): Promise<void> => {
    await axios.post('/api/admin/scoring', scoring);
}