import React, {FC, useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {v4} from "uuid";
import {getScoring, saveScoring, Scoring, ScoringCategory, Topic} from "../../api/scoring";
import {Hint, LabelBold, Title} from "../../atoms/Typography";
import {Column, Row} from "../../atoms/Layout";
import Loading from "../../atoms/Loading";
import Panel from "../../atoms/Panel";
import {TextInput} from "../../atoms/Input";
import {HorizontalSpaceFlex1} from "../../atoms/Space";
import Button from "../../atoms/Button";
import Pill from "../../atoms/Pill";
import EditScoringTopic from "../../templates/EditScoringTopic";
import EditScoringCategory from "../../templates/EditScoringCategory";

const EditScoring: FC = () => {
    const params = useParams();
    const scoringId: string | undefined = params['scoringId'];
    const [savedScoring, setSavedScoring] = useState<Scoring | undefined>(undefined);
    useEffect(() => {
        if (scoringId) {
            getScoring(scoringId).then(setSavedScoring)
        } else {
            setSavedScoring({
                id: v4(),
                name: '',
                topics: [],
                scoringCategories: []
            })
        }
    }, [scoringId]);

    const save = async () => {
        const scoring: Scoring = {
            id: savedScoring?.id || v4(),
            name,
            topics,
            scoringCategories
        }

        setSaving(true)
        try {
            await saveScoring(scoring)
        } catch (e) {
            console.error(e)
            alert('Failed to save scoring system')
        }
        setSaving(false)
        setSavedScoring(scoring)
    };

    const [name, setName] = useState<string>(savedScoring?.name || '');
    const [topics, setTopics] = useState<Topic[]>(savedScoring?.topics || []);
    const [scoringCategories, setScoringCategories] = useState(savedScoring?.scoringCategories || []);
    const [saving, setSaving] = useState(false);
    const [editingTopicId, setEditingTopicId] = useState<string | undefined>(undefined);
    const editingTopic = editingTopicId ? topics.find((topic) => topic.id == editingTopicId) : undefined
    const [editingScoringCategoryId, setEditingScoringCategoryId] = useState<string | undefined>(undefined);
    const editingScoringCategory = editingScoringCategoryId ? scoringCategories.find((sc) => sc.id == editingScoringCategoryId) : undefined

    useEffect(() => {
        if (savedScoring) {
            setName(savedScoring.name)
            setTopics(savedScoring.topics)
            setScoringCategories(savedScoring.scoringCategories)
        }
    }, [savedScoring]);

    const saveTopic = (topic: Topic) => {
        const existingTopic = topics.find((t) => t.id == topic.id);
        if (existingTopic) {
            setTopics(topics.map((t) => t.id == topic.id ? topic : t))
        } else {
            setTopics([...topics, topic])
            setEditingTopicId(topic.id)
        }
    }

    const deleteTopic = (topicId: string) => {
        setTopics(topics.filter((t) => t.id != topicId))
        if (editingTopicId == topicId) {
            setEditingTopicId(undefined)
        }
    }

    const saveScoringCategory = (sc: ScoringCategory) => {
        const existingSc = scoringCategories.find((c) => c.id === sc.id);
        if (existingSc) {
            setScoringCategories(scoringCategories.map((c) => c.id == sc.id ? sc : c))
        } else {
            setScoringCategories([...scoringCategories, sc])
            setEditingScoringCategoryId(sc.id)
        }
    }

    const deleteScoringCategory = (scId: string) => {
        setScoringCategories(scoringCategories.filter((sc) => sc.id != scId))
        if (editingScoringCategoryId == scId) {
            setEditingScoringCategoryId(undefined)
        }
    }

    const hasUnsavedChanges = useMemo(() => {
        if (name !== savedScoring?.name) {
            return true
        }

        if (JSON.stringify(topics) !== JSON.stringify(savedScoring?.topics)) {
            return true
        }

        return JSON.stringify(scoringCategories) !== JSON.stringify(savedScoring?.scoringCategories);


    }, [name, topics, scoringCategories, savedScoring]);

    if (savedScoring === undefined) {
        return <Row center>
            <Loading />
        </Row>
    }

    return <>
        <Title>{ scoringId ? `Editing ${savedScoring?.name}` : "New Scoring System"}</Title>
        <Panel>
            <Column gap="medium">
                <LabelBold>Scoring System Name:</LabelBold>
                <TextInput value={name} onChange={setName} />

                <LabelBold>Topics:</LabelBold>
                <Row vcenter gap="small">
                    { topics.map((topic) => <Pill key={topic.id} active={editingTopicId == topic.id} onClick={() => setEditingTopicId(topic.id)}>{topic.name}</Pill>) }
                    <Pill active={!editingTopicId} onClick={() => setEditingTopicId(undefined)}>+</Pill>
                </Row>
                <EditScoringTopic topic={editingTopic} onSave={saveTopic} onDelete={deleteTopic} />

                <LabelBold>Scoring Categories:</LabelBold>
                <Hint>Each scoring category appears as a score on the users' insights page</Hint>
                <Row vcenter gap="small">
                    { scoringCategories.map((sc) => <Pill key={sc.id} active={editingScoringCategoryId == sc.id} onClick={() => setEditingScoringCategoryId(sc.id)}>{sc.name}</Pill>) }
                    <Pill active={!editingScoringCategoryId} onClick={() => setEditingScoringCategoryId(undefined)}>+</Pill>
                </Row>
                <EditScoringCategory topics={topics} scoringCategory={editingScoringCategory} onSave={saveScoringCategory} onDelete={deleteScoringCategory} />

                <Row>
                    <HorizontalSpaceFlex1 />
                    <Button onClick={save} disabled={!hasUnsavedChanges || saving}>{ saving ? 'Saving...' : 'Save'}</Button>
                </Row>
            </Column>
        </Panel>
    </>
}

export default EditScoring
