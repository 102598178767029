import React, { type FC } from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import ScheduleCEOMeeting from 'pages/ScheduleCEOMeeting';
import { Screen } from 'atoms/Layout';
import Onboarding from 'pages/Onboarding';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login';
import Feedback from './pages/Feedback/Feedback';
import PrivacyPolicy from 'pages/PrivacyPolicy';
import TermsOfService from 'pages/TermsOfService';
import Admin from "./pages/Admin/Admin";
import MailUnsubscribe from "./pages/MailUnsubscribe";
const App: FC = () => {
  return (
      <Screen>
          <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/schedule" element={<ScheduleCEOMeeting />} />
              <Route path="/onboarding" element={<Onboarding />} />
              <Route path="/dashboard/*" element={<Dashboard />} />
              <Route path="/feedback/*" element={<Feedback />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/admin/*" element={<Admin />} />
              <Route path="/unsubscribe/:subscriptionId" element={<MailUnsubscribe />} />
          </Routes>
      </Screen>
  )
}

export default App
