import {ScoringCategory, ScoringCategoryTopic, Topic} from "../api/scoring";
import React, {FC, useEffect, useMemo, useState} from "react";
import {v4} from "uuid";
import Panel from "../atoms/Panel";
import {Column, Row} from "../atoms/Layout";
import {Body, Hint, Label, LabelBold} from "../atoms/Typography";
import {TextAreaInput, TextInput} from "../atoms/Input";
import SliderInput from "../atoms/SliderInput";
import {HorizontalSpaceFlex1} from "../atoms/Space";
import Button from "../atoms/Button";

interface EditScoringCategory {
    topics: Topic[];
    scoringCategory: ScoringCategory | undefined;
    onSave: (scoringCategory: ScoringCategory) => void;
    onDelete: (scoringCategoryId: string) => void;
}

const EditScoringCategory: FC<EditScoringCategory> = ({topics, scoringCategory, onSave, onDelete}) => {
    const [name, setName] = useState(scoringCategory?.name || '');
    const [description, setDescription] = useState(scoringCategory?.description || '');
    const savedWeights: Record<string, number> = useMemo(() => {
        const weights: Record<string, number> = {};
        topics.forEach((topic) => {
            weights[topic.id] = 0;
        });
        scoringCategory?.topics.forEach((topic) => {
            weights[topic.topicId] = topic.weight;
        });
        return weights;
    }, [topics, scoringCategory?.topics]);
    const [weights, setWeights] = useState(savedWeights);

    useEffect(() => {
        if (scoringCategory) {
            setName(scoringCategory.name);
            setDescription(scoringCategory.description);
            setWeights(savedWeights);
        } else {
            setName('');
            setDescription('');
            setWeights(savedWeights);
        }
    }, [scoringCategory, savedWeights]);

    const id = useMemo(() => scoringCategory?.id || v4(), [scoringCategory]);

    const hasUnsavedChanges = useMemo(() => {
        if (name !== scoringCategory?.name) {
            return true;
        }

        if (description !== scoringCategory?.description) {
            return true;
        }

        return JSON.stringify(savedWeights) !== JSON.stringify(weights);


    }, [name, description, weights, savedWeights, scoringCategory]);

    const setWeight = (topicId: string, weight: number) => {
        setWeights({...weights, [topicId]: weight});
    }

    const save = () => {
        const categoryTopics: ScoringCategoryTopic[] = [];
        Object.keys(weights).forEach((topicId) => {
            if (weights[topicId] === 0) {
                return;
            }
            if (!topics.find((topic) => topic.id === topicId)) {
                return;
            }
            categoryTopics.push({topicId, weight: weights[topicId]});
        });
        const category: ScoringCategory = {id, name, description, topics: categoryTopics}
        onSave(category);
    };

    return <Panel secondary>
        <Column gap="small">
            <LabelBold>{scoringCategory ? `Editing score ${scoringCategory.name}` : "New Score"}</LabelBold>
            <Label>Name:</Label>
            <Hint>This is the score name as seen by the users</Hint>
            <TextInput value={name} onChange={setName} />
            <Label>Description:</Label>
            <Hint>This description appears to the user as a tooltip, to explain what the score is and what factors into it.</Hint>
            <TextAreaInput value={description} onChange={setDescription} />
            <Label>Topic Weights:</Label>
            <Hint>These affect how heavily each topic factors into the final score</Hint>
            { topics.map((topic) => <Row key={topic.id} vcenter gap="large">
                <div style={{width: 200}}>
                    <Body>{topic.name}</Body>
                </div>
                <div style={{width: 400}}>
                    <SliderInput value={weights[topic.id]} onChange={(value) => setWeight(topic.id, value)} />
                </div>
                <Body>
                    {((weights[topic.id] ?? 0) * 100).toFixed(1)}%
                </Body>
            </Row>) }
            <Row gap="small">
                <HorizontalSpaceFlex1 />
                {scoringCategory && <Button onClick={() => onDelete(scoringCategory.id)}>Delete Score</Button>}
                <Button onClick={save} disabled={!hasUnsavedChanges}>Save Score</Button>
            </Row>

        </Column>

    </Panel>
}

export default EditScoringCategory