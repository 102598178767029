import React, { FC } from 'react';
import OnboardingLayout from 'molecules/OnboardingLayout';
import { Row } from 'atoms/Layout';
import OnboardingForm from 'templates/OnboardingForm';

const Onboarding: FC = () => {
    return <OnboardingLayout marketingTitle="You're about to get access to:">
        <Row center>
            <OnboardingForm />
        </Row>
    </OnboardingLayout>
}

export default Onboarding;
