
import moment from "moment";
import {Topic} from "./scoring";

export interface InsightTheme {
    content: string;
    topic?: Topic;
    sentimentScore: number;
}

export interface Insights {
    themes: InsightTheme[];
    actionPlan: string; // Markdown
    feedbackCount: number;
    createdAt: string;
}

export type InsightStreamingSource = 'theme' | 'actionPlan'

export type InsightStreamingEvent = InsightStreamingStart | InsightStreamingStep | InsightStreamingEnd

export interface InsightStreamingStart {
    type: 'start',
    feedbackCount: number;
    themes: {
        topic?: Topic;
        sentimentScore: number;
        index: number;
    }[]
}

export interface InsightStreamingStep {
    type: 'step',
    source: InsightStreamingSource;
    index: number;
    content: string;
}

export interface InsightStreamingEnd {
    type: 'end',
}

export interface InsightStream {
    stopStream: () => void
}

export const streamInsights = (onChunk: (result: Insights) => void, channelId?: string): InsightStream => {
    const eventSource = new EventSource(channelId ? `/api/insights/${channelId}/stream` : '/api/insights/stream')
    let insights: Insights = {
        themes: [],
        actionPlan: '',
        feedbackCount: 0,
        createdAt: moment().toISOString()
    }

    const onStart = (start: InsightStreamingStart) => {
        insights.feedbackCount = start.feedbackCount
        insights.actionPlan = ''
        insights.themes = start.themes.map(t => ({
            content: '',
            topic: t.topic,
            sentimentScore: t.sentimentScore
        }))
        onChunk({...insights})
    }

    const onEvent = (event: InsightStreamingStep) => {
        if (event.source === 'theme') {
            insights.themes[event.index].content += event.content
        } else if (event.source === 'actionPlan') {
            insights.actionPlan += event.content
        }
        onChunk({...insights})
    }

    eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data) as InsightStreamingEvent
        // If it has a feedbackCount, it's the start event
        if (data.type === 'start') {
            onStart(data)
        } else if (data.type === 'end') {
            eventSource.close()
        } else {
            onEvent(data)
        }
    }

    return {
        stopStream: () => {
            eventSource.close()
        }
    }
}
