import styled from 'styled-components';
import { ThemeProps } from '../theme';

const Background = styled.div<ThemeProps>`
    background-color: ${({ feedbackTheme }) => feedbackTheme.colors.background};
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    font-family: Oswald !important;
`;

export default Background;
