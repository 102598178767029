import React, {FC, useEffect, useState} from 'react';
import { Column, HorizontalDivider, Row } from 'atoms/Layout';
import Panel from 'atoms/Panel';
import InsightScore from 'organisms/InsightScore';
import { useAppContext } from 'context/AppContext';
import Loading from 'atoms/Loading';
import { Label, Title } from 'atoms/Typography';
import { SENTIMENT_NAMES, toSentiment } from 'model/feedback';
import OverallSentimentBreakdown from 'organisms/OverallSentimentBreakdown';
import Accordion from '../molecules/Accordion';
import {getScoring, Scoring} from "../api/scoring";

interface Props {
    channelId?: string;
}

const InsightScores: FC<Props> = ({ channelId }) => {
    const { feedback, channels } = useAppContext();
    const channel = channels.value?.find(c => c.id === channelId);
    const [scoring, setScoring] = useState<Scoring | undefined>(undefined);
    const [hasScoring, setHasScoring] = useState(false);
    const relatedFeedback = feedback.value?.filter(f => !channelId || f.channelId === channelId) ?? []
    useEffect(() => {
        if (channel) {
            setHasScoring(true)
            getScoring(channel.scoringId).then(setScoring);
        }
    }, [channel]);

    const averageSentiment = relatedFeedback.length ? relatedFeedback.reduce((acc, f) => acc + f.analysis.sentimentScore, 0) / relatedFeedback.length : 0;
    const sentiment = toSentiment(averageSentiment);

    if (!hasScoring) {
        return <></>;
    }

    if (feedback.loading || !scoring) {
        return <Column gap="large" size="half">
            <Panel>
                <Row center>
                    <Loading size="large" />
                </Row>
            </Panel>
            <Panel>
                <Row center>
                    <Loading size="large" />
                </Row>
            </Panel>
        </Column>
    }

    return <Column gap="large" size="half">
        <Panel>
            <Column gap="medium">
                <Label>Overall Sentiment</Label>
                <Row>
                    <Title>{SENTIMENT_NAMES[sentiment]}</Title>
                </Row>
                <OverallSentimentBreakdown feedback={relatedFeedback} />
            </Column>
        </Panel>
        <Panel>
            <Column gap="large">
                <Accordion title="What's the Symantiq Index?">
                    Introducing our innovative scoring system: Rating building excellence by blending user voice notes with external data like reviews, social media comments and industry sources. Scored across Product, Environment, Service, Community, and Accessibility categories, it paints a holistic picture. Benchmark, monitor building health, and share specific category reports effortlessly.
                </Accordion>
                {
                    scoring.scoringCategories.map(score => <>
                        <HorizontalDivider key={`${score.name}-divider`}/>
                        <InsightScore feedback={relatedFeedback} scoringCategory={score} />
                    </>)
                }
            </Column>
        </Panel>
    </Column>
}

export default InsightScores;
