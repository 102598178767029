import axios from 'axios';
import { FeedbackPage } from './feedbackPage';
import {MailSubscription} from "./mail";

export enum ChannelCategory {
    PHYSICAL_LOCATION = 'PHYSICAL_LOCATION',
    COMMUNITY_OUTREACH = 'COMMUNITY_OUTREACH',
    EVENT = 'EVENT',
    DIGITAL = 'DIGITAL'
}

export interface Channel {
    id: string;
    spaceId: string;
    name: string;
    category: ChannelCategory;
    prompt?: string;
    scoringId: string;
    hasLogo: boolean;
}

export interface UpdateChannelCommand {
    name: string;
    category: ChannelCategory;
    prompt?: string;
    scoringId: string;
    clearLogo: boolean;
    addLogo?: File;
}

export interface ChannelPublicInformation {
    feedbackPage: FeedbackPage;
    prompt?: string;
    logoOverrideUrl?: string;
    hasLogo: boolean;
}

export const getChannels = async (): Promise<Channel[]> => {
    return (await axios.get('/api/channels')).data
}

export const createChannel = async (name: string, category: ChannelCategory): Promise<Channel> => {
    return (await axios.post('/api/channel/create', { name, category })).data
}

export const updateChannel = async (id: string, command: UpdateChannelCommand): Promise<Channel> => {
    const formData = new FormData()
    formData.append('name', command.name)
    formData.append('category', command.category)
    formData.append('scoringId', command.scoringId)
    formData.append('clearLogo', command.clearLogo.toString())
    if (command.prompt) {
        formData.append('prompt', command.prompt || '')
    }
    if (command.addLogo) {
        formData.append('logo', command.addLogo)
    }
    return (await axios.post(`/api/channel/${id}/update`, formData)).data
}

export const getChannelLogoSrc = (channelId: string): string => {
    return `/api/channel/${channelId}/logo`
}

export const getChannelPublicInformation = async (channelId: string): Promise<ChannelPublicInformation | null> => {
    try {
        return (await axios.get(`/api/channel/public/${channelId}`)).data
    } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
            return null
        }
        throw error
    }
}

export const getChannelNotificationSubscriptions = async (channelId: string): Promise<MailSubscription[]> => {
    return (await axios.get(`/api/channel/${channelId}/notifications/subscriptions`)).data
}

export const subscribeToChannelNotifications = async (channelId: string, email: string): Promise<MailSubscription> => {
    return (await axios.post(`/api/channel/${channelId}/notifications/subscribe`, { email })).data
}
