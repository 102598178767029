import React, { FC } from 'react';
import BrandNameSrc from './BrandName.svg';
import BrandNameLightSrc from './BrandNameLight.svg';

interface Props {
    light?: boolean;
}

const BrandName: FC<Props> = ({ light }) => {
    return <img src={light ? BrandNameLightSrc : BrandNameSrc} alt="Symantiq Logo" width="136" height="32" />
}

export default BrandName;
