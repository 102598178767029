
import React from 'react';
import styled from 'styled-components';

export const RainbowColorPane = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.10) 0%, rgba(0, 0, 0, 0.10) 100%), linear-gradient(180deg, rgba(255, 255, 255, 0.50) 0%, rgba(255, 255, 255, 0.50) 100%), conic-gradient(from 144deg at 47.69% 20.89%, #FFF 0.6795244105160236deg, #FFF 84.375deg, rgba(255, 171, 171, 0.43) 178.1249964237213deg, rgba(148, 144, 249, 0.69) 287.00374603271484deg, #FFEAC7 330.4158353805542deg, rgba(255, 255, 255, 0.50) 360deg);
  flex: 1;
  box-shadow: 0 4px 35px 0 rgba(206, 206, 206, 0.25);
  width: 100%;
  padding: 100px 200px;
  height: 100%;
  
@media (max-width: 768px) {
  padding: 100px 50px;
}
`;


