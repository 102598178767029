import {BodyBold, Hint, Subtitle} from "../atoms/Typography";
import {FC, useEffect, useState} from "react";
import {Column, Row} from "../atoms/Layout";
import {MailSubscription, unsubscribe} from "../api/mail";
import {getChannelNotificationSubscriptions, subscribeToChannelNotifications} from "../api/channels";
import Loading from "../atoms/Loading";
import {TextInput} from "../atoms/Input";
import Button from "../atoms/Button";
import {HorizontalSpaceFlex1} from "../atoms/Space";
import {XMark} from "../atoms/Icon";

interface Props {
    channelId: string;
}

const ChannelNotificationSettings: FC<Props> = ({ channelId }) => {
    const [subscriptions, setSubscriptions] = useState<MailSubscription[] | undefined>(undefined);
    const [emailInput, setEmailInput] = useState<string>('');
    const isValidEmail = emailInput.match(/.+@.+\..+/);
    const [saving, setSaving] = useState<boolean>(false);

    useEffect(() => {
        setSubscriptions(undefined);
        getChannelNotificationSubscriptions(channelId).then(setSubscriptions);
    }, [channelId]);

    const subscribe = async () => {
        if (!isValidEmail) return;
        if (!subscriptions) return;
        if (subscriptions.find(sub => sub.email === emailInput)) return;
        setSaving(true);
        const newSubscription = await subscribeToChannelNotifications(channelId, emailInput);
        setSaving(false);
        setSubscriptions([...subscriptions, newSubscription]);
        setEmailInput('');
    }

    const remove = async (subscription: MailSubscription) => {
        if (!subscriptions) return;
        setSubscriptions(subscriptions.filter(sub => sub.id !== subscription.id));
        await unsubscribe(subscription.id)
    }

    return (<Column gap="medium">
        <Subtitle>Channel Notification Settings</Subtitle>
        <Hint>You can add people here by email address.  Email addresses you add will automatically receive a notification when someone leaves feedback on this channel.</Hint>

        <Column gap="small">
            {subscriptions === undefined && <Row center><Loading size="medium" /></Row>}
            {subscriptions?.map(subscription => <Row vcenter>
                <BodyBold>{subscription.email}</BodyBold>
                <HorizontalSpaceFlex1 />
                <XMark width="24" style={{cursor: "pointer"}} onClick={() => remove(subscription)} />
            </Row> )}
            {subscriptions && <Row vcenter gap="small">
                <TextInput value={emailInput} onChange={setEmailInput} />
                <Button disabled={!isValidEmail || saving} onClick={subscribe}>Add</Button>
            </Row> }
        </Column>
    </Column>)
}

export default ChannelNotificationSettings;
