import React, { FC } from 'react';
import Wizard from '../organisms/Wizard';
import { Space } from '../api/spaces';
import { useNavigate } from 'react-router-dom';
import SpaceForm from "../pages/Dashboard/Settings/Space";

const WelcomeWizard: FC = () => {
    const navigate = useNavigate()

    return <Wizard currentStep={1} stepCount={1}>
        <SpaceForm onSuccess={() => navigate('/dashboard/home')} />
    </Wizard>
}

export default WelcomeWizard;
