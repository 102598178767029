
import React, { FC, useEffect } from 'react';
import Button from 'atoms/Button';

interface Props {
    content: string,
    children?: React.ReactNode
}

const ClipboardButton: FC<Props> = ({ content, children }) => {
    const [copied, setCopied] = React.useState(false)

    const copyWelcomeEmail = () => {
        void navigator.clipboard.writeText(content)
        setCopied(true)
    }

    useEffect(() => {
        setCopied(false)
    }, [content])

    return<Button onClick={copyWelcomeEmail}>{copied ? 'Copied' : children}</Button>
}

export default ClipboardButton;
