import React, { FC } from 'react';
import { Column, Row } from 'atoms/Layout';
import { RainbowColorPane } from 'atoms/Pane';
import { Body, Subtitle } from 'atoms/Typography';
import MonitorImage from 'atoms/MonitorImage';
import Logo from 'atoms/Logo';

interface Props {
    marketingTitle: string;
    children?: React.ReactNode;
}

const OnboardingLayout: FC<Props> = ({ marketingTitle, children}) => {
    return <Row size="full">
        <Column size="full" padding="large" gap="medium">
            <Row padding="large">
                <Logo />
            </Row>
            {children}
        </Column>
        <Column size="full">
            <RainbowColorPane>
                <Column>
                    <div style={{ width: '500px', marginBottom: '100px' }}>
                        <Subtitle>
                            {marketingTitle}
                        </Subtitle>
                        <Body>
                            <ul>
                                <li>Symantiq easy tile setup</li>
                                <li>Branded tiles and interface</li>
                                <li>Unlimited tiles to get guest feedback and actionable insights</li>
                            </ul>
                        </Body>
                    </div>
                    <MonitorImage />
                </Column>
            </RainbowColorPane>
        </Column>
    </Row>
}

export default OnboardingLayout;
