import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import colors from './colors';

interface Props {
    value: number;
    onChange: (value: number) => void;
}

const RangeTrack = styled.div`
    position: relative;
    height: 12px;
    border-radius: 6px;
    background: ${colors.brand};
    max-width: 400px;
`

const RangeThumb = styled.div<{value: number}>`
    position: absolute;
    top: -10px;
    left: calc(${props => (props.value * 100).toFixed(2)}% - 16px);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${colors.background};
    border: 1px solid ${colors.borderSecondary};
    padding: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`


const SliderInput: FC<Props> = ({ value, onChange}) => {
    const trackRef = React.useRef<HTMLDivElement>(null);
    const [dragging, setDragging] = React.useState(false);

    const handleMouseMove = (event: MouseEvent, overrideDragging?: boolean) => {
        if (!dragging && !overrideDragging) return;
        const rect = trackRef.current?.getBoundingClientRect();
        if (rect) {
            let newHue = ((event.clientX - rect.left) / rect.width);
            newHue = Math.max(0, Math.min(1, newHue)); // Ensure within range
            onChange(newHue);
        }
        event.preventDefault();
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setDragging(true);
        handleMouseMove(event.nativeEvent, true); // Initial hue calculation
        event.preventDefault()
    };

    const handleMouseUp = (event: MouseEvent) => {
        setDragging(false);
        handleMouseMove(event);
    };

    useEffect(() => {
        if (dragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [dragging]);

    return <RangeTrack ref={trackRef} onMouseDown={handleMouseDown}>
        <RangeThumb value={value} onMouseDown={handleMouseDown} />
    </RangeTrack>
}

export default SliderInput;
