import React, { FC, useEffect } from 'react';
import { FormColumn, Row } from '../../../atoms/Layout';
import { updateSpace } from '../../../api/spaces';
import { useAppContext } from '../../../context/AppContext';
import Loading from '../../../atoms/Loading';
import { Hint, Label } from '../../../atoms/Typography';
import {TextAreaInput, TextInput} from '../../../atoms/Input';
import { VerticalSpace2 } from '../../../atoms/Space';
import Button from '../../../atoms/Button';
import {ContextField} from "../../../api/context";
import InformationTooltip from "../../../molecules/InformationTooltip";
import RadioGroup from "../../../molecules/RadioGroup";
import TagGroupInput from "../../../molecules/TagGroupInput";

interface ContextFieldInputProps {
    field: ContextField
    value: string
    onChange: (value: string) => void
}

const NewlinesToBr: FC<{text: string}> = ({text}) => {
    return <>{text.split('\n').map((item, key) => {
        if (!item.trim()) {
            return null
        }
        return <span key={key}>{item}<br/><br/></span>
    })}</>
}

const ContextFieldInput: FC<ContextFieldInputProps> = ({field, value, onChange}) => {
    return <>
        <Row vcenter gap="small">
            <Label>
                {field.question}
            </Label>
            {field.infoTooltip && <InformationTooltip><NewlinesToBr text={field.infoTooltip}/> </InformationTooltip>}
        </Row>
        {field.hint && <Hint>{field.hint}</Hint>}
        {field.type === 'shortText' && <TextInput value={value} onChange={onChange} max={64} half placeholder={field.placeholder} />}
        {field.type === 'longText' && <TextAreaInput value={value} onChange={onChange} max={4098} placeholder={field.placeholder} />}
        {field.type === 'radioGroup' && <RadioGroup options={field.options.map(o => ({ value: o, label: o }))} onChange={onChange} value={value} />}
        {field.type === 'tagGroup' && <TagGroupInput options={field.options.map(o => ({ value: o, label: o }))} onChange={onChange} value={value} maxSelected={field.maxSelections} />}

        <VerticalSpace2 />
    </>
}

function filterRecord(record: Record<string, string | null | undefined>): Record<string, string> {
    const filteredRecord: Record<string, string> = {};

    Object.keys(record).forEach(key => {
        const value = record[key];
        if (value !== null && value !== undefined && value.trim() !== '') {
            filteredRecord[key] = value;
        }
    });

    return filteredRecord;
}

const SpaceForm: FC<{ onSuccess?: () => void }> = ({ onSuccess }) => {
    const space = useAppContext().space
    const contextFields = useAppContext().contextFields

    useEffect(() => {
        void space.load()
        void contextFields.load()
    }, []);

    const [saving, setSaving] = React.useState<boolean>(false)
    const [saved, setSaved] = React.useState<boolean>(false)
    const [spaceName, setSpaceName] = React.useState<string>('')
    const [contextValues, setContextValues] = React.useState<Record<string, string>>({})

    useEffect(() => {
        if (space.value) {
            setSpaceName(space.value.name)
            setContextValues(space.value.contextValues)
        }
    }, [space])

    const invalidData = !spaceName.trim()

    const onSubmit = async () => {
        if (!space.value) {
            return
        }

        setSaving(true)

        try {
            const newSpace = await updateSpace({
                name: spaceName,
                welcomeEmailSent: space.value?.welcomeEmailSent ?? false,
                contextValues: filterRecord(contextValues)
            })

            space.setValue(newSpace)
            setSaving(false)
            setSaved(true)
            onSuccess?.()
        } catch (e) {
            setSaving(false)
            console.error(e)
        }
    }

    if (!space) {
        return <Row size="full" center>
            <Loading size="large" />
        </Row>
    }

    return <FormColumn gap="small">
        <Label>Space Name</Label>
        <TextInput value={spaceName} onChange={setSpaceName} max={64} half />
        <VerticalSpace2 />
        { contextFields.value && contextFields.value.map(field =>
            <ContextFieldInput key={field.key}
                               field={field}
                               value={contextValues[field.key] ?? ''}
                               onChange={v => setContextValues({...contextValues, [field.key]: v})}
            />)
        }
        { saved && <Hint>Space saved!</Hint> }
        <Row>
            <Button disabled={!space || invalidData} onClick={onSubmit} loading={saving}>Save</Button>
        </Row>
    </FormColumn>
}

export default SpaceForm;
