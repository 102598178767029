
import React, { FC, useEffect } from 'react';
import WelcomeWizard from '../templates/WelcomeWizard';
import { getUser } from '../api/users';
import { useNavigate } from 'react-router-dom';

const Welcome: FC = () => {
    const navigate = useNavigate()

    useEffect(() => {
        getUser().then((user) => {
            if (user === null) {
                navigate('/login')
            }
        })
    }, [])

    return <WelcomeWizard />
}

export default Welcome;
