import React, {FC, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAppContext} from "../../../context/AppContext";
import {Column, Row} from "../../../atoms/Layout";
import Loading from "../../../atoms/Loading";
import {getChannelLogoSrc, updateChannel} from "../../../api/channels";
import PhonePreview from "../../../atoms/PhonePreview";
import FeedbackWizard from "../../../feedbackPage/templates/FeedbackWizard";
import {Hint, LabelBold, LinkButton, Subtitle} from "../../../atoms/Typography";
import {toFeedbackTheme} from "../../../feedbackPage/theme";
import {TextInput} from "../../../atoms/Input";
import FileUpload from "../../../molecules/FileUpload";
import Button from "../../../atoms/Button";
import ChannelNotificationSettings from "../../../templates/ChannelNotificationSettings";
import {VerticalSpace3} from "../../../atoms/Space";

export const ChannelSettings: FC = () => {
    const params = useParams();
    const channelId = params.channelId;
    const { channels, feedbackPage } = useAppContext();
    useEffect(() => {
        void channels.load();
        void feedbackPage.load();
    }, []);
    const channel = channels.value?.find(channel => channel.id === channelId);

    const [prompt, setPrompt] = useState<string>('');
    const [logoUrl, setLogoUrl] = useState<string | null>(null);
    const [logoFile, setLogoFile] = useState<File | null>(null);

    const [saving, setSaving] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [previewKey, setPreviewKey] = useState<number>(0);


    useEffect(() => {
        if (channel) {
            setPrompt(channel.prompt ?? 'Let us know how your experience was today');
            if (channel.hasLogo) {
                setLogoUrl(getChannelLogoSrc(channel.id));
            } else {
                setLogoUrl(null);
            }
            setLogoFile(null);
        }

    }, [channel]);


    if (!channel || !channelId || !feedbackPage.loaded || !feedbackPage.value) {
        return <Row center>
            <Loading />
        </Row>
    }

    const onLogoUpload = (file: File | undefined) => {
        setLogoFile(file ?? null)
        setLogoUrl(file ? URL.createObjectURL(file) : null)
    }

    const onSubmit = async () => {
        setSaving(true);
        try {
            await updateChannel(channelId, {
                name: channel.name,
                category: channel.category,
                prompt: prompt,
                scoringId: channel.scoringId,
                clearLogo: logoUrl === null,
                addLogo: logoFile ?? undefined
            });
            void channels.load(true);
            setSaved(true);
        } catch (e) {
            console.error(e);
        } finally {
            setSaving(false);
        }
    }

    const theme = toFeedbackTheme(feedbackPage.value, prompt, logoUrl ?? undefined, true);

    return <Row>
        <Column size="full" padding="medium" gap="medium" style={{ maxWidth: '400px', minWidth: '300px'}}>
            <Column gap="medium">
                <Subtitle>Channel Feedback Page Settings</Subtitle>
                <LabelBold>Prompt / Question</LabelBold>
                <Hint>This is the test the user will see to prompt them to provide feedback</Hint>
                <TextInput value={prompt} onChange={setPrompt} max={255} />

                <LabelBold>Upload the logo for this channel</LabelBold>
                <Hint>
                    This will be used to personalize your feedback page
                    <ul>
                        <li>Must be a transparent logo that is visible on either a black or white background</li>
                        <li>PNG format</li>
                        <li>Max size 2MB</li>
                        <li>If you do not provide a logo for this channel, your company logo will be used instead</li>
                    </ul>
                </Hint>
                <FileUpload value={logoFile ?? undefined} onChange={onLogoUpload} />
                { saved && <Hint>Changes Saved!</Hint>}
                <Row>
                    <Button onClick={onSubmit} loading={saving}>Save changes</Button>
                </Row>
            </Column>
            <VerticalSpace3 />
            <ChannelNotificationSettings channelId={channelId} />
        </Column>
        <Column gap="medium">
            <PhonePreview>
                <FeedbackWizard theme={theme} key={previewKey} onSubmit={async (value) => console.log(value)} />
            </PhonePreview>
            <Row center>
                <LinkButton onClick={() => setPreviewKey(prev => prev + 1)}>Refresh Preview</LinkButton>
            </Row>
        </Column>
    </Row>
}

export default ChannelSettings;
