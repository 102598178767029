import React, { FC } from 'react';
import Preview from './Preview.png'
import styled from 'styled-components';

const MonitorImageImg = styled.img`
  margin-left: -100px;
  margin-right: -200px;
  width: calc(100% + 300px);

  @media (max-width: 768px) {
    width: 100svw;
    margin-left: -50px;
  }
`;

const MonitorImage: FC = () => {
    return <MonitorImageImg src={Preview} alt="Symantiq - Continuous, effortless feedback for your public spaces" />
}

export default MonitorImage;
