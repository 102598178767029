import React, {FC} from "react";
import {Row} from "../atoms/Layout";
import Pill from "../atoms/Pill";

export interface TagOption {
    value: string
    label: string
}

interface Props {
    value?: string
    options: TagOption[]
    onChange: (value: string) => void
    maxSelected?: number
}

const TagGroupInput: FC<Props> = ({ value, options, onChange, maxSelected }) => {
    const activeValues =  value ? value.split(';') : [];
    const toggle = (value: string) => {
        if (activeValues.includes(value)) {
            onChange(activeValues.filter(v => v !== value).join(';'));
        } else {
            if (!maxSelected || activeValues.length < maxSelected) {
                onChange([...activeValues, value].join(';'));
            }
        }
    }

    return <Row gap="small">
        { options.map(o => {
            return <Pill key={o.value} active={activeValues.includes(o.value)} onClick={() => toggle(o.value)}>{o.label}</Pill>
        }) }
    </Row>
}

export default TagGroupInput;
